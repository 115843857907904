import { Component, Input, OnInit } from '@angular/core';
import { DistributionRequestComponent } from '../../../distribution-request/distribution-request.component';
import { MatDialogRef } from '@angular/material/dialog';
import { RequestTypeEnum } from '../../enums/account-update.enum';
import { RequestSurveyFormModel } from '../../models/request-survey-form.model';
import { AccountModel, RequestsClient } from 'src/app/core/clients/generated/client';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
})
export class CompleteComponent implements OnInit  {
  constructor(
    private dialogRef: MatDialogRef<DistributionRequestComponent>,
    private requestsClient: RequestsClient,
  ) { }

  @Input() complete?:boolean;
  @Input() crmRequestID?:number;
  @Input() surveyID?:number;
  @Input() requestType?: RequestTypeEnum;
  @Input() account?:AccountModel;
  requestTypeEnum = RequestTypeEnum;
  requestTypeName = '';
  survey = new RequestSurveyFormModel(0, 0, '', 0, '', '');
  surveyComplete = false;
  surveySubmitCount = 0;
  showSurvey = false;

  closeRequest(): void {
    this.dialogRef.close(false);
  }

  submitSurvey(): void {
    this.requestsClient.createRequestSurvey(this.survey.RequestID, this.survey.InvestmentAccountID, this.survey.Rating, this.survey.RequestDescription, this.survey.GoodNotes, this.survey.BadNotes).subscribe({
      next: (result) => {
        console.log(result);
        this.surveyID = result;
      },
      error: () => {
        this.surveySubmitCount++;
        // for work around on sometimes the first request failing submitting to the crm.
        if (this.surveySubmitCount < 2){
          console.log('received error, retrying in 2 seconds');
          setTimeout(() =>{
            this.submitSurvey();
          }, 2000);
        } else {
          this.surveySubmitCount = 0;
        }
      },
      complete: () => {
        this.surveyComplete = true;
        return this.surveyID;
      }
    });
  }

  ngOnInit(): void {

    this.requestTypeName = this.requestType === this.requestTypeEnum.contribution ? 'contribution' : this.requestType === this.requestTypeEnum.distribution ? 'distribution' : this.requestType === this.requestTypeEnum.reallocation ? 'model allocation / trade' : this.requestType === this.requestTypeEnum.closeAccount ? 'close account': this.requestType === this.requestTypeEnum.planningDesk ? 'planning desk' : '';

    if (this.requestType === RequestTypeEnum.contribution || this.requestType === RequestTypeEnum.distribution || this.requestType === RequestTypeEnum.reallocation || this.requestType === RequestTypeEnum.closeAccount){

      this.survey.RequestID = this.crmRequestID as number;
      this.survey.RequestDescription = this.requestTypeName;
      this.survey.InvestmentAccountID = this.account?.investmentAccountID as number;

      if (this.survey.RequestID && this.survey.InvestmentAccountID)
        this.showSurvey = true;
    }
  }
}